<template>
  <div>
    <h6 class="text-center mt-5" v-if="!orders.length">No Data Found</h6>
    <template v-else>
      <v-row justify="center">
        <v-col cols="8">
          <v-text-field
            placeholder="Order No"
            rounded
            type="number"
            v-model.number="searchQ"
            filled
            dense
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="2">
          <v-icon large class="mt-1"> mdi-filter </v-icon>
        </v-col> -->
      </v-row>
      <h6 class="text-center mt-5" v-if="!filterorders.length">
        No Data Found
      </h6>

      <history-card
        v-else
        v-for="(order, index) in filterorders"
        :key="index"
        :title="order.title | capitalize"
        :orderId="order.orderId"
        :orderIdToken="order.orderIdToken"
        :date="order.date"
        :time="order.time"
        :total="(+order.total).toFixed(2)"
        :hostname="order.hostname"
        :service="order.service"
        :status="order.status.toLowerCase()"
      ></history-card>
    </template>
  </div>
</template>

<script>
import HistoryCard from '@/components/OrdersHistory/HistoryCard';
export default {
  name: 'OrderHistoryCardsWithSearch',
  components: { HistoryCard },
  props: ['orders'],
  data: function () {
    return {
      searchQ: null,
    };
  },
  computed: {
    filterorders: function () {
      if (this.searchQ) {
        return this.orders?.filter((order) => {
          return order.orderId.toString().includes(this.searchQ);
        });
      } else return this.orders;
    },
  },
};
</script>

<style scoped></style>
