<template>
  <v-card color="mb-3" class="historycard">
    <v-list-item three-line class="align-items-center">
      <v-list-item-action>
        <v-list-item-action-text class="font-weight-bold light-blue--text text--darken-1" @click="viewOrder()">
          <a>#{{ orderId }}</a>
        </v-list-item-action-text>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <b>{{ title }}</b>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ (formattedDate + ' ' + this.time) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon class="align-self-center flex-column text-center">
        <div
          style="cursor: pointer"
          @click="dialog = true"
          :class="statusBackgroundColor(status) + ' ' + statusTextColor(status) + ' rounded-pill alert status'"
          role="alert"
        >
          {{ status | capitalize }}
        </div>
        <p class="title" v-if="!['successful', 'cancelled', 'declined'].includes(status)">
          <b><sup>RM</sup>{{ total }}</b>
        </p>
        <v-btn
          @click="reorder()"
          outlined
          color="primary"
          class="text-capitalize"
          small
          v-else
        >
          Reorder
        </v-btn>
      </v-list-item-icon>
    </v-list-item>
    <Largeordermodal
      v-if="dialog"
      v-model="dialog"
      :isHistoryDialog="true"
      :orderid="orderId"
      :status="status"
    >
    </Largeordermodal>

    <v-bottom-sheet
      v-if="status == 'declined' || status == 'successful'"
      v-model="sheet"
      class="cartsheet"
    >
      <v-sheet class="text-center reordersheet" height="400px">
        <v-row class="align-items-center px-5">
          <v-spacer></v-spacer>
          <v-col cols="8" class="text-center">
            <h5 class="font-weight-6 p-0">Order Summary</h5>
          </v-col>
          <v-col cols="2">
            <v-btn class="my-3" text color="grey" @click="sheet = !sheet">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Largeordermodal from '../DialogModals/AcceptedOrderModal';
import dayjs from 'dayjs';
import kebabCase from 'lodash/kebabCase';

export default {
  props: ['orderId', 'orderIdToken', 'title', 'date', 'time', 'total', 'hostname', 'service', 'status'],
  components: {
    Largeordermodal,
  },
  data() {
    return {
      dialog: false,
      sheet: false,
    };
  },
  computed: {
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn',
    }),
    formattedDate: function() {
      return dayjs(this.date).format('YYYY-MM-DD');
    }
  },
  methods: {
    viewOrder() {
      if (this.isLoggedIn) {
        this.$router.push(`/order/${this.orderId}`);
      } else {
        this.$router.push(`/receipt/${this.orderIdToken}`);
      }
    },
    reorder() {
      window.location = `https://${this.hostname}/menu?service=${this.service}`;
    },
    statusBackgroundColor(status) {
      return kebabCase(status);
    },
    statusTextColor(status) {
      return ['declined', 'cancelled', 'pending'].includes(kebabCase(status)) ? '' : 'white--text'
    },
  },
  created() {},
};
</script>

<style>
.alert-dark {
  background: #efefef !important;
  border: 0;
  color: #cbcbcb;
}
.historycard .status {
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
</style>
